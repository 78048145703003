import React from 'react'
import { Our_Clients_Data } from './Data'
import { useTranslation } from 'react-i18next';
import cookies from "js-cookie";


const OurClients = () => {
    const { t } = useTranslation();
    const currentLanguageCode = cookies.get('i18next');
    return (
        <>
            {/* Mobile */}
            <div className='block md:hidden'>
                <div className='flex flex-col items-center mb-[60px]'>
                    <h1 className='text-[32px] font-semibold text-[#333D46] mb-10'>{t('Our_Clients')}</h1>

                    <div className='flex flex-wrap justify-center gap-4'>
                        {Our_Clients_Data.map((card) => (
                            <div key={card.id} className='group relative w-[150px] h-[220px]'>
                                <img className='w-full h-full rounded-[20px] object-cover' src={card.img} alt={card.titleEN} />
                                <div className='absolute inset-0 bg-[#496071] rounded-[20px] opacity-0 group-hover:opacity-75'></div>
                                <h1 className='absolute inset-0 flex justify-center text-[14px] group-hover:text-[16px] items-center text-white font-semibold'>
                                    {currentLanguageCode === 'en' ? card.titleEN : card.titleAR}
                                </h1>
                            </div>
                        ))}
                    </div>
                </div>
            </div>


            {/* Desktop */}
            <div className='hidden md:flex flex-col items-center mb-[172px]'>
                <h1 className='text-[48px] font-semibold text-[#333D46] mb-20'>{t('Our_Clients')}</h1>

                <div className='flex gap-5'>
                    {Our_Clients_Data.map((card) => (
                        <>
                            <div className='group relative'>
                                <img className='w-[231px] h-[336px] rounded-[20px]' src={card.img} alt={card.titleEN} />
                                <div className='absolute inset-0 bg-[#496071] w-[231px] h-[336px] rounded-[20px] opacity-0 group-hover:opacity-75'></div>
                                <h1 className='absolute inset-0 flex justify-center text-[0px] group-hover:text-[20px] items-center text-white font-semibold'>{currentLanguageCode === 'en' ? card.titleEN : card.titleAR}</h1>
                            </div>
                        </>
                    ))}
                </div>
            </div>
        </>
    )
}

export default OurClients