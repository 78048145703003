import React from 'react'
import dribbble from '../images/Footer/dribbble.png'
import instagram from '../images/Footer/instagram.png'
import facebook from '../images/Footer/facebook.png'
import { useTranslation } from 'react-i18next'
import MultilineText from './MultilineText'
import logoSVG from '../images/logoSVG.svg'
import company_profile from '../images/Footer/company_profile.png'
import vat_logo from '../images/Footer/vat_logo.png'
import cookies from "js-cookie";

const Footer = () => {
    const { t } = useTranslation();
    const currentLanguageCode = cookies.get('i18next');
    const emailSubjectEN = 'Inquiry About Elevator Services';
    const emailSubjectAR = 'استفسار حول خدمات المصاعد';

    return (

        <>
            {/* Mobile */}
            <div className="md:hidden">
                {/* Up Footer */}
                <div className="flex flex-col px-6 pb-8 pt-6 bg-[#3A4650]">
                    {/* Logo Section */}
                    <div className="flex justify-start items-center mb-6">
                        <img className="w-12 h-12" src={logoSVG} alt="logo" />
                        <h1
                            className="text-[20px] leading-6 text-[#F3F7F8] font-semibold ml-3"
                            dangerouslySetInnerHTML={{ __html: t('App_Name') }}
                        />
                    </div>
                    {/* <h1 className="text-[14px] text-[#C6D4DB] mb-8">
                        <MultilineText text={t('Footer_Long_Text')} />
                    </h1> */}

                    {/* Links Section */}
                    <div className="grid grid-cols-2 gap-4 text-[14px] text-[#C6D4DB]">
                        {/* Attachments */}
                        <div>
                            <h1 className="text-[#F3F7F8] text-[16px] font-bold mb-4">{t('Attachments')}</h1>
                            <button
                                onClick={() => window.open('https://drive.google.com/file/d/1g7v3MitWRWmna3HNG5QqZwKCYcU_cpP5/view?usp=sharing', '_blank')}
                                className="flex items-center mb-4"
                            >
                                <img className="h-4 w-4 mr-2" src={company_profile} alt="company profile" />
                                <h1>{t('Our_Profile')}</h1>
                            </button>
                            <button
                                onClick={() => window.open('https://drive.google.com/file/d/1T3HJm13unuxNFNUR8g6pgTUdxiz7sL04/view?usp=sharing', '_blank')}
                            >
                                <img className="w-10" src={vat_logo} alt="vat logo" />
                            </button>
                        </div>

                        {/* Company */}
                        <div>
                            <h1 className="text-[#F3F7F8] text-[16px] font-bold mb-4">{t('Company')}</h1>
                            <a className="mb-4 block" href="/about">{t('About_Us')}</a>
                            <a className="block" href="/contact">{t('Contact')}</a>
                        </div>

                        {/* Contact Us */}
                        <div>
                            <h1 className="text-[#F3F7F8] text-[16px] font-bold mb-4">{t('Countact_Us')}</h1>
                            <a className="mb-4 block" href="/">{t('Website')}</a>
                            <a className="mb-4 block" href={`mailto:info@quad-dimensions.com?subject=${currentLanguageCode === 'en' ? emailSubjectEN : emailSubjectAR}`}>
                                {t('Email')}
                            </a>
                            <a className="block" href="https://wa.me/966534223238">{t('Whatsapp')}</a>
                        </div>

                        {/* Support */}
                        <div>
                            <h1 className="text-[#F3F7F8] text-[16px] font-bold mb-4">{t('Support')}</h1>
                            <a className="mb-4 block" href="https://wa.me/966534223238">{t('Get_a_Quote')}</a>
                            <a className="block" href="https://wa.me/966534223238">{t('Booking')}</a>
                        </div>

                        {/* Office */}
                        <div>
                            <h1 className="text-[#F3F7F8] text-[16px] font-bold mb-4">{t('Office')}</h1>
                            <a className="mb-4 block" href="/about">
                                <MultilineText text={t('location_typing')} />
                            </a>
                            <div>
                                {/* 
                                <a className="mb-4 block" href="tel:+966534223238">058 124 0150</a>
                                <a className="mb-4 block" href="tel:+966533566889">053 356 6889</a>
                                <a className="block" href="tel:+966502093053">050 209 3053</a> 
                                */}
                                <a className="block" href="tel:+966534223238">053-422-3238</a>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Down Footer */}
                <div className="flex flex-col items-center bg-[#708FA0] px-6 py-4 text-center">
                    <h1 className="text-[14px] text-white mb-4">{t('Copywrite')}</h1>
                    <div className="flex gap-4">
                        <a href="https://google.com"><img className="w-6 h-6" src={dribbble} alt="dribbble" /></a>
                        <a href="https://google.com"><img className="w-6 h-6" src={instagram} alt="instagram" /></a>
                        <a href="https://google.com"><img className="w-6 h-6" src={facebook} alt="facebook" /></a>
                    </div>
                </div>
            </div>

            {/* Desktop */}
            <div className='hidden md:block'>
                {/* Up Footer */}
                <div className='flex justify-between px-20 pb-[67px] pt-[44px] bg-[#3A4650]'>
                    <div className='flex flex-col'>
                        <div className='flex justify-start mb-2 items-center'>
                            <img className='w-[53.89px] h-[66px]' src={logoSVG} alt='logo' />
                            <h1 className='text-[24px] leading-7 text-[#F3F7F8] font-semibold' dangerouslySetInnerHTML={{ __html: t('App_Name') }} />
                        </div>
                        {/* <h1 className='text-[14px] text-[#C6D4DB]'>
                            <MultilineText text={t('Footer_Long_Text')} />
                        </h1> */}
                    </div>

                    <div className='flex justify-start gap-[52px]'>


                        <div className='flex flex-col text-[14px] text-[#C6D4DB]'>
                            <h1 className='text-[#F3F7F8] text-[16px] font-bold mb-[26px]'>{t('Attachments')}</h1>
                            <button
                                onClick={() => window.open('https://drive.google.com/file/d/1g7v3MitWRWmna3HNG5QqZwKCYcU_cpP5/view?usp=sharing', '_blank')}
                            >
                                <div className='flex justify-start mb-[20px]'>
                                    <img className='h-5 w-5 me-2' src={company_profile} alt='company profile' />
                                    <h1>{t('Our_Profile')}</h1>
                                </div>
                            </button>
                            <button
                                onClick={() => window.open('https://drive.google.com/file/d/1T3HJm13unuxNFNUR8g6pgTUdxiz7sL04/view?usp=sharing', '_blank')}
                            >
                                <img className='w-[60px] ' src={vat_logo} alt='vat logo' />
                            </button>
                        </div>

                        <div className='flex flex-col text-[14px] text-[#C6D4DB]'>
                            <h1 className='text-[#F3F7F8] text-[16px] font-bold mb-[26px]'>{t('Company')}</h1>
                            <a className='mb-[20px]' href='/about'>
                                <h1>{t('About_Us')}</h1>
                            </a>
                            <a className='mb-[20px]' href='/contact'>
                                <h1>{t('Contact')}</h1>
                            </a>
                        </div>

                        <div className='flex flex-col text-[14px] text-[#C6D4DB]'>
                            <h1 className='text-[#F3F7F8] text-[16px] font-bold mb-[26px]'>{t('Countact_Us')}</h1>
                            <a className='mb-[20px]' href='/'>
                                <h1>{t('Website')}</h1>
                            </a>
                            <a className='mb-[20px]' href={`mailto:info@quad-dimensions.com?subject=${currentLanguageCode === 'en' ? emailSubjectEN : emailSubjectAR}`}>
                                <h1>{t('Email')}</h1>
                            </a>
                            <a className='mb-[20px]' href='https://wa.me/966534223238'>
                                <h1>{t('Whatsapp')}</h1>
                            </a>
                        </div>

                        <div className='flex flex-col text-[14px] text-[#C6D4DB]'>
                            <h1 className='text-[#F3F7F8] text-[16px] font-bold mb-[26px]'>{t('Support')}</h1>
                            <a className='mb-[20px]' href='https://wa.me/966534223238'>
                                <h1>{t('Get_a_Quote')}</h1>
                            </a>
                            <a className='mb-[20px]' href='https://wa.me/966534223238'>
                                <h1>{t('Booking')}</h1>
                            </a>
                        </div>

                        <div className='flex flex-col text-[14px] text-[#C6D4DB]'>
                            <h1 className='text-[#F3F7F8] text-[16px] font-bold mb-[26px]'>{t('Office')}</h1>
                            <a className='mb-[20px]' href='/about'>
                                <h1><MultilineText text={t('location_typing')} /></h1>
                            </a>
                            <div>
                                {/* 
                                <a className='mb-[20px]' href='tel:+966534223238'>
                                    <h1>058 124 0150</h1>
                                </a>
                                <a className='mb-[20px]' href='tel:+966533566889'>
                                    <h1>053 356 6889</h1>
                                </a>
                                <a className='mb-[20px]' href='tel:+966502093053'>
                                    <h1>050 209 3053</h1>
                                </a>
                                 */}
                                <a className="block" href="tel:+966534223238">053-422-3238</a>
                            </div>
                        </div>

                    </div>
                </div>

                {/* Down Footer */}
                <div className='flex justify-between bg-[#708FA0] px-20 py-6'>
                    {/* <div className='flex '>
                    <button>
                        <img src={company_profile} />
                    </button>
                    <button>
                        <img src={vat_logo} />
                    </button>
                </div> */}
                    <div>
                        <h1 className='text-[16px] text-white'>{t('Copywrite')}</h1>
                    </div>
                    <div className='flex justify-start gap-5'>
                        <a href='https://google.com'> <img src={dribbble} alt='dribbble' /></a>
                        <a href='https://google.com'> <img src={instagram} alt='instagram' /></a>
                        <a href='https://google.com'> <img src={facebook} alt='facebook' /></a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer