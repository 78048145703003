// import { t } from 'i18next';
import React, { useState, useEffect } from 'react';
import { Our_Designs_Data } from './Data';
import cookies from "js-cookie";
import { useTranslation } from 'react-i18next';
import MultilineText from './MultilineText';

const OurDesigns = () => {
    const [activeCard, setActiveCard] = useState('Cabins');
    const [activeImageIndex, setActiveImageIndex] = useState({});
    const currentLanguageCode = cookies.get('i18next');
    const { t } = useTranslation();

    // Function to handle automatic image change
    useEffect(() => {
        const intervalIds = {};

        // Set intervals for each card to change images automatically
        Our_Designs_Data.forEach((card) => {
            intervalIds[card.id] = setInterval(() => {
                setActiveImageIndex((prevState) => {
                    const currentIndex = prevState[card.id] || 0;
                    const nextIndex = (currentIndex + 1) % card.images.length;
                    return {
                        ...prevState,
                        [card.id]: nextIndex,
                    };
                });
            }, 3000); // Change image every 3 seconds
        });

        // Cleanup intervals on component unmount
        return () => {
            Object.values(intervalIds).forEach(clearInterval);
        };
    }, []);

    // Function to change the active image
    const handleImageChange = (cardId, index) => {
        setActiveImageIndex((prevState) => ({
            ...prevState,
            [cardId]: index,
        }));
    };

    return (
        <>

            {/* Mobile */}
            <div className='block md:hidden'>
                <div className='flex flex-col justify-center items-start bg-[#F3F7F8] my-[50px] pt-[40px] pb-[60px]'>

                    <div className='flex flex-col w-full items-center'>
                        <h1 className='text-[32px] font-semibold text-[#333D46] mb-[16px]'>{t('Our_Designs')}</h1>
                        <p className='text-[16px] text-[#708FA0] text-center mb-[40px]'><MultilineText text={t('our_design_long_text')} /> </p>
                    </div>

                    <div className='grid grid-cols-2 gap-4'>
                        {Our_Designs_Data.map((card) => (
                            <div key={card.id} className={`flex flex-col bg-white rounded-[20px] pt-[18px] ps-5 pe-3 gap-[12px]`} onClick={() => setActiveCard(card.id)}>

                                <div className='flex flex-col justify-between'>

                                    <div>
                                        <h1 className='text-[14px] font-semibold text-[#333D46]'>
                                            {currentLanguageCode === 'en' ? card.titleEN : card.titleAR}
                                        </h1>

                                        {/* Tags (buttons for images) displayed vertically on mobile */}
                                        <div className='flex flex-col gap-2 mt-3'>
                                            {card.images.map((image, index) => (
                                                <button
                                                    key={index}
                                                    className={`text-[10px] ${activeImageIndex[card.id] === index ? 'bg-[#496071] text-white' : 'bg-[#E4E7E8] text-[#333D46]'} py-1 px-2 rounded-full`}
                                                    onClick={(e) => {
                                                        e.stopPropagation(); // Prevent card click
                                                        handleImageChange(card.id, index);
                                                    }}
                                                >
                                                    {currentLanguageCode === 'en' ? card.titlesEN[index] : card.titlesAR[index]}
                                                </button>
                                            ))}
                                        </div>

                                        {/* Description always visible */}
                                        <p className={`text-[12px] text-[#9FB5C1] mt-2`} dangerouslySetInnerHTML={{ __html: currentLanguageCode === 'en' ? card.descriptionEN : card.descriptionAR }}></p>

                                    </div>

                                    {/* Button to browse designs */}
                                    {activeCard === card.id && (
                                        <>
                                            {card.link && (
                                                <button
                                                    onClick={() => window.open(card.link)}
                                                    className='text-[14px] w-[120px] font-medium text-[#F3F7F8] bg-[#496071] py-2 px-4 rounded-[62px] mb-4'>
                                                    {t('Browse_designs')}
                                                </button>
                                            )}
                                        </>
                                    )}
                                </div>

                                {/* Image is displayed based on the active image's state */}
                                <div className='flex flex-col items-center'>
                                    <img
                                        className={`${activeCard === card.id ? 'w-[80px] h-[140px]' : 'max-h-[80px]'}`}
                                        src={card.images[activeImageIndex[card.id] || 0]} // Use active image index or default to the first image
                                        alt={card.titleEN}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Desktop */}
            <div className='hidden md:flex flex-col justify-center items-start bg-[#F3F7F8] my-[100px] pt-[68px] pb-[121px]'>

                <div className='flex flex-col w-full items-center'>
                    <h1 className='text-[48px] font-semibold text-[#333D46] mb-[26px]'>{t('Our_Designs')}</h1>
                    <p className='text-[20px] text-[#708FA0] text-center mb-[78px]'><MultilineText text={t('our_design_long_text')} /> </p>
                </div>

                <div className='flex gap-5 overflow-x-auto no-scrollbar ps-[100px]'>
                    {Our_Designs_Data.map((card) => (
                        <div key={card.id} className={`flex flex-none ${activeCard === card.id ? 'justify-between' : 'flex-col'} bg-white rounded-[20px] pt-[26px] ps-5 pe-3 gap-[20px]`} onClick={() => setActiveCard(card.id)}>

                            <div className='flex flex-col justify-between'>

                                <div>
                                    <h1 className='text-[16px] font-semibold text-[#333D46]'>{currentLanguageCode === 'en' ? card.titleEN : card.titleAR}</h1>

                                    {/* Tags (buttons for images) displayed horizontally below the <h1> and <p> */}
                                    <div className='flex gap-2 mt-3'>
                                        {card.images.map((image, index) => (
                                            <button
                                                key={index}
                                                className={`text-[12px] ${activeImageIndex[card.id] === index ? 'bg-[#496071] text-white' : 'bg-[#E4E7E8] text-[#333D46]'} py-1 px-3 rounded-full`}
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevent card click
                                                    handleImageChange(card.id, index);
                                                }}
                                            >
                                                {currentLanguageCode === 'en' ? card.titlesEN[index] : card.titlesAR[index]}
                                            </button>
                                        ))}
                                    </div>

                                    {/* Description always visible */}
                                    <p className={`text-[14px] text-[#9FB5C1] mt-3`} dangerouslySetInnerHTML={{ __html: currentLanguageCode === 'en' ? card.descriptionEN : card.descriptionAR }}></p>

                                </div>
                                {/* Button to browse designs */}
                                {activeCard === card.id && (
                                    <>
                                        {card.link && (
                                            <button
                                                onClick={() => window.open(card.link)}
                                                className='text-[16px] w-[154px] font-medium text-[#F3F7F8] bg-[#496071] py-2 px-4 rounded-[62px] mb-5'>
                                                {t('Browse_designs')}
                                            </button>
                                        )}
                                    </>
                                )}
                            </div>

                            {/* Image is displayed based on the active image's state */}
                            <div className='flex flex-col items-center'>
                                <img
                                    className={`${activeCard === card.id ? 'w-[112px] h-[200px]' : 'max-h-[102px]'}`}
                                    src={card.images[activeImageIndex[card.id] || 0]} // Use active image index or default to the first image
                                    alt={card.titleEN}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default OurDesigns;
