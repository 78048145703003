import React, { useState } from 'react'
import logoSVG from '../images/logoSVG.svg'
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cookies from "js-cookie";
import i18next from 'i18next';

const Navbar = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const [showNavbar, setShowNavbar] = React.useState(false);

    const currentLanguageCode = cookies.get('i18next')
    const [language, setLanguage] = useState(currentLanguageCode); // State to handle re-render

    const changeLanguage = (lng) => {
        i18next.changeLanguage(lng);
        cookies.set('i18next', lng); // Ensure cookie updates
        setLanguage(lng); // Trigger a re-render by updating state
        window.location.reload();
    };
    return (
        <>
            {/* Mobile */}
            <div className="block md:hidden px-4 py-4 relative">


                {/* // Header Section */}
                {location.pathname === '/' && (

                    <div className="flex items-center justify-between">
                        {/* Logo and App Name */}
                        <div className="flex items-center gap-2">
                            <img className="w-[40px] h-[50px]" src={logoSVG} alt="logo" />
                            <h1 className="text-white text-[20px]" dangerouslySetInnerHTML={{ __html: t('App_Name') }} />
                        </div>

                        {/* Language and Menu Icon */}
                        <div className="flex items-center gap-4 ">
                            {language === 'en' ? (
                                <button className="border text-white px-2 py-1 rounded-lg" onClick={() => changeLanguage('ar')}>
                                    <h1>ع</h1>
                                </button>
                            ) : (
                                <button className="border text-white px-2 py-1 rounded-lg" onClick={() => changeLanguage('en')}>
                                    <h1>EN</h1>
                                </button>
                            )}
                            <button
                                onClick={() => setShowNavbar(!showNavbar)}
                                className="text-white text-[24px]">
                                &#9776; {/* Menu Icon */}
                            </button>
                        </div>
                    </div>
                )}

                {location.pathname === '/about' && (

                    <div className="flex items-center justify-between">
                        {/* Logo and App Name */}
                        <div className="flex items-center gap-2">
                            <img className="w-[40px] h-[50px]" src={logoSVG} alt="logo" />
                            <h1 className="text-[#3C4C59] text-[20px]" dangerouslySetInnerHTML={{ __html: t('App_Name') }} />
                        </div>

                        {/* Language and Menu Icon */}
                        <div className="flex items-center gap-4 ">
                            {language === 'en' ? (
                                <button className="border text-[#3C4C59] px-2 py-1 rounded-lg" onClick={() => changeLanguage('ar')}>
                                    <h1>ع</h1>
                                </button>
                            ) : (
                                <button className="border text-[#3C4C59] px-2 py-1 rounded-lg" onClick={() => changeLanguage('en')}>
                                    <h1>EN</h1>
                                </button>
                            )}
                            <button
                                onClick={() => setShowNavbar(!showNavbar)}
                                className="text-black text-[24px]">
                                &#9776; {/* Menu Icon */}
                            </button>
                        </div>
                    </div>
                )}

                {location.pathname === '/contact' && (

                    <div className="flex items-center justify-between">
                        {/* Logo and App Name */}
                        <div className="flex items-center gap-2">
                            <img className="w-[40px] h-[50px]" src={logoSVG} alt="logo" />
                            <h1 className="text-[#3C4C59] text-[20px]" dangerouslySetInnerHTML={{ __html: t('App_Name') }} />
                        </div>

                        {/* Language and Menu Icon */}
                        <div className="flex items-center gap-4 ">
                            {language === 'en' ? (
                                <button className="border text-[#3C4C59] px-2 py-1 rounded-lg" onClick={() => changeLanguage('ar')}>
                                    <h1>ع</h1>
                                </button>
                            ) : (
                                <button className="border text-[#3C4C59] px-2 py-1 rounded-lg" onClick={() => changeLanguage('en')}>
                                    <h1>EN</h1>
                                </button>
                            )}
                            <button
                                onClick={() => setShowNavbar(!showNavbar)}
                                className="text-black text-[24px]">
                                &#9776; {/* Menu Icon */}
                            </button>
                        </div>
                    </div>
                )}

                {/* Title and Description */}
                {/* <div className="mt-4 text-center">
                    <h2 className="text-white text-[18px] font-bold">{t('Title')}</h2>
                    <p className="text-white text-[14px] mt-2">{t('Description')}</p>
                </div> */}

                {/* Buttons Section */}
                {/* <div className="mt-6 flex justify-center gap-4">
                    <button
                        onClick={() => window.open('https://wa.me/966534223238', '_blank')}
                        className="px-4 py-2 text-white bg-mainGray text-[14px] rounded-lg">
                        {t('Get_a_Quote')}
                    </button>
                    <button
                        className="px-4 py-2 text-white bg-blue-600 text-[14px] rounded-lg"
                        onClick={() => alert('Button Action')}>
                        {t('Second_Button')}
                    </button>
                </div> */}

                {/* Full-Screen Navbar Overlay */}
                {showNavbar && (
                    <div className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-80 flex flex-col items-center justify-center z-50">
                        <div className="flex flex-col gap-6 text-[20px] text-white">
                            <a className={location.pathname === '/' ? 'text-[#708FA0]' : ''} href="/">
                                {t('Home')}
                            </a>
                            <a className={location.pathname === '/about' ? 'text-[#708FA0]' : ''} href="/about">
                                {t('navbar_About_Us')}
                            </a>
                            <a className={location.pathname === '/contact' ? 'text-[#708FA0]' : ''} href="/contact">
                                {t('Contact_Us')}
                            </a>
                        </div>

                        {/* Close Button */}
                        <button
                            onClick={() => setShowNavbar(false)}
                            className="absolute top-4 right-4 text-white text-[24px]">
                            &times; {/* Close Icon */}
                        </button>
                    </div>
                )}
            </div>

            {/* Desktop */}
            <div className='hidden md:grid grid-cols-4 mx-24 pt-7 items-center' >

                {
                    location.pathname === '/' && (

                        <div className='flex justify-start items-center col-span-3 gap-72'>
                            <div className='flex items-center gap-1'>
                                <img className='w-[53.89px] h-[66px]' src={logoSVG} alt='logo' />
                                <h1 className='text-white text-[24px] leading-6' dangerouslySetInnerHTML={{ __html: t('App_Name') }} />
                            </div>

                            <div className='flex justify-center w-full gap-10 text-[18px] text-white z-50'>
                                <a className='text-[#708FA0]' href='/'>{t('Home')}</a>
                                <a href='/about'>{t('navbar_About_Us')}</a>
                                <a href='/contact'>{t('Contact_Us')}</a>
                                {language === 'en' ?
                                    <button className="border-[1px] px-4 rounded-lg" onClick={() => changeLanguage('ar')}>
                                        <h1>ع</h1>
                                    </button>
                                    :
                                    <button className="border-[1px]  px-4 rounded-lg" onClick={() => changeLanguage('en')}>
                                        <h1>EN</h1>
                                    </button>
                                }
                            </div>
                        </div>
                    )
                }
                {
                    location.pathname === '/about' && (

                        <div className='flex justify-start items-center col-span-3 gap-72'>
                            <div className='flex items-center gap-1'>
                                <img className='w-[53.89px] h-[66px]' src={logoSVG} alt='logo' />
                                <h1 className='text-[#3C4C59] text-[24px] leading-6' dangerouslySetInnerHTML={{ __html: t('App_Name') }} />
                            </div>

                            <div className='flex justify-center w-full gap-10 text-[18px] text-[#3C4C59]'>
                                <a href='/'>{t('Home')}</a>
                                <a className='text-[#708FA0]' href='/about'>{t('navbar_About_Us')}</a>
                                <a href='/contact'>{t('Contact_Us')}</a>
                                {language === 'en' ?
                                    <button className="border-[1px]  px-4 rounded-lg" onClick={() => changeLanguage('ar')}>
                                        <h1>ع</h1>
                                    </button>
                                    :
                                    <button className="border-[1px] px-4 rounded-lg" onClick={() => changeLanguage('en')}>
                                        <h1>EN</h1>
                                    </button>
                                }
                            </div>
                        </div>
                    )
                }
                {
                    location.pathname === '/contact' && (

                        <div className='flex justify-start items-center col-span-3 gap-72'>
                            <div className='flex items-center gap-1'>
                                <img className='w-[53.89px] h-[66px]' src={logoSVG} alt='logo' />
                                <h1 className='text-[#3C4C59] text-[24px] leading-6' dangerouslySetInnerHTML={{ __html: t('App_Name') }} />
                            </div>

                            <div className='flex justify-center w-full gap-10 text-[18px] text-[#3C4C59]'>
                                <a href='/'>{t('Home')}</a>
                                <a href='/about'>{t('navbar_About_Us')}</a>
                                <a className='text-[#708FA0]' href='/contact'>{t('Contact_Us')}</a>
                                {language === 'en' ?
                                    <button className="border-[1px]  px-4 rounded-lg" onClick={() => changeLanguage('ar')}>
                                        <h1>ع</h1>
                                    </button>
                                    :
                                    <button className="border-[1px] px-4 rounded-lg" onClick={() => changeLanguage('en')}>
                                        <h1>EN</h1>
                                    </button>
                                }
                            </div>
                        </div>
                    )
                }

                <div className='flex justify-end gap-20'>
                    <button
                        onClick={() => window.open('https://wa.me/966534223238', '_blank')}
                        className='px-6 py-3 text-white bg-mainGray text-[16px] rounded-[10px]'>{t('Get_a_Quote')}</button>
                </div>
            </div>
        </>
    )
}

export default Navbar