import { useTranslation } from 'react-i18next';
import React, { useState } from 'react'
import { Safety_Data } from './Data'
import cookies from "js-cookie";
import MultilineText from './MultilineText';


const Safety = () => {
    const { t } = useTranslation();
    const [activeImage, setActiveImage] = useState(Safety_Data[2]); // Default image source (you can replace '' with a default image if needed)
    const currentLanguageCode = cookies.get('i18next');

    return (
        <>
            {/* Mobile */}
            <div className='block md:hidden'>
                {/* <div className="overflow-hidden whitespace-nowrap py-2 pb-3 text-3xl font-extrabold text-[#496071] relative">
                    <div className={`inline-block ${currentLanguageCode === 'en' ? "animate-scrollEN_Mobile" : "animate-scrollAR_Mobile"}`}>
                        "{t('issuing')}"
                    </div>
                </div> */}

                <div className='flex flex-col pb-[40px] pt-[40px] px-4'>

                    <h1 className='text-[32px] font-semibold text-[#333D46] mb-6'>{t('Safety')}</h1>

                    <p className='text-[16px] text-[#708FA0] mb-[20px]'>
                        <MultilineText text={t('we_concerned_with')} />
                    </p>

                    <div className='flex flex-col items-center gap-6'>
                        <ul className="list-disc text-[#3A4650] font-bold text-[18px] text-start w-full px-6">
                            <li className='mb-6 cursor-pointer' onClick={() => setActiveImage(Safety_Data[0])}>{t('Mechanical_systems')}</li>
                            <li className='mb-6 cursor-pointer' onClick={() => setActiveImage(Safety_Data[1])}>{t('Electrical_systems')}</li>
                            <li className='mb-6 cursor-pointer' onClick={() => setActiveImage(Safety_Data[2])}>{t('Connecting_to_support_systems')}</li>
                        </ul>
                        <img
                            className='w-full h-[250.52px] rounded-[20px] object-cover'
                            src={activeImage}
                            alt='Systems_img'
                        />
                    </div>
                </div>
            </div>


            {/* Desktop */}


            <div className='hidden md:block'>
                {/* <div className="overflow-hidden whitespace-nowrap py-2 pb-3 text-4xl font-extrabold text-[#496071] relative">
                    <div className={`inline-block ${currentLanguageCode === 'en' ? "animate-scrollEN" : "animate-scrollAR"}`}>
                        "{t('issuing')}"
                    </div>
                </div> */}


                <div className='flex flex-col pb-[100px] pt-[70px] '>



                    <h1 className='text-[48px] font-semibold text-[#333D46] mb-11 px-[100px]'>{t('Safety')}</h1>



                    <p className='text-[20px] text-[#708FA0] mb-[38px] px-[100px]'><MultilineText text={t('we_concerned_with')} /></p>
                    <div className='flex justify-between items-center gap-24 px-[100px]'>
                        <ul className="list-disc  text-[#3A4650] font-bold gap-6 text-[32px] text-start">
                            <li className='mb-10 cursor-pointer' onClick={() => setActiveImage(Safety_Data[0])} >{t('Mechanical_systems')}</li>
                            <li className='mb-10 cursor-pointer' onClick={() => setActiveImage(Safety_Data[1])} >{t('Electrical_systems')}</li>
                            <li className='cursor-pointer' onClick={() => setActiveImage(Safety_Data[2])} >{t('Connecting_to_support_systems')}</li>
                        </ul>
                        <img className='h-[504px] w-[604px] rounded-[20px] object-cover' src={activeImage} alt='Systems_img' />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Safety